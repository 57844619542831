export default {
  methods: {
    /* nom race (ou nr si non défini) */
    getRaceLabel(race) {
      if (!race) {
        return '';
      }
      if (this.currentLang === 'fr') {
        return race.fr;
      }
      if (this.currentLang === 'en') {
        return race.en;
      }
      return `Race nr ${race.id}`;
    },
    /* nb chiens à juger */
    // getRaceStats(dogs) {
    //   return `${dogs.length} chien${dogs.length > 1 ? 's' : ''} à juger.`;
    // },
    getDogClassLabel(dogClass) {
      if (this.currentLang === 'fr') { // todo not needed !
        return dogClass.fr;
      }
      return dogClass.en;
    },
    getExpoLabel() {
      if (!this.currentExpo) {
        return '';
      }
      if (this.currentLang === 'fr') {
        return this.currentExpo && this.currentExpo.nom && this.currentExpo.nom.fr;
      }
      if (this.currentLang === 'en') {
        return this.currentExpo && this.currentExpo.nom && this.currentExpo.nom.en;
      }
      return '';
    },
    /* Affichage de la date fonction de la locale de l'utilisateur */
    getExpoDate() {
      const date = this.getDateFR(this.currentExpo.date_expo);
      if (!date) {
        return '';
      }
      let localeStr = 'en-US';
      if (this.currentLang === 'fr') {
        localeStr = 'fr-FR';
      }

      return date.toLocaleDateString(
        [localeStr],
        {
          weekday: 'long',
          month: 'long',
          day: '2-digit',
          year: 'numeric',
        },
      );
    },
    /**
     * Convert date from French format (DD/MM/YYYY)
     * @param dt
     * @returns {null|Date}
     */
    getDateFR(dt) {
      if (!dt || dt === '') {
        return null;
      }
      const splitDt = dt.split('/');
      if (splitDt.length !== 3) {
        return null;
      }
      return new Date(splitDt[2], splitDt[1] - 1, splitDt[0]);
    },
    /**
     * ie. Remplacement "09 H 15" par 915 pour comparaison d'horaires
     * @param hr
     * @returns {number}
     */
    getHoursToNumber(hr) {
      if (!hr || hr.length === 0) {
        return 0;
      }
      const hrMin = hr.toLowerCase().split('h');
      if (hrMin.length !== 2) {
        return 0;
      }
      return parseInt(hrMin[0].trim(), 10) * 100 + parseInt(hrMin[1].trim(), 10);
    },
    getNumberToHours(nb) {
      const hrs = parseInt(nb / 100, 10).toString();
      const mins = parseInt(nb % 100, 10).toString();
      return `${hrs.padStart(2, '0')}h${mins.padStart(2, '0')}`;
    },
  },
};
