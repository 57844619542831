<template>
  <div class="page-expos">
    <div class="container">
      <div class="reload" @click="resync"><font-awesome-icon icon="sync" /></div>
      <h1>{{ $t("show.title", { name: currentUser.nom }) }}</h1>
      <h2>{{ getExpoLabel(currentExpo) }}</h2>
      <h4>{{ getExpoDate(currentExpo) }}</h4>
    <div v-if="isLoading" class="loading">
      {{ $t('global.loading') }}
    </div>

      <div :class="`race${race.status === 'done' ? ' completed' : ''}`"
        v-for="race in getDogsStatusByRace"
        :key="race.id" :set="hours = getBoundaries(race.todo.concat(race.done))">

        <span  v-if="race.status === 'done'" class="valid"></span>

        <router-link :to="{ name: 'race', params: {id: race.id} }">
          <button >{{ getRaceLabel(getRaceById(race.id)) }}</button>
        </router-link>

        <ul>
          <li>
            {{ $tc("show.nb_dogs_to_evaluate", race.todo.length, {
              count: race.todo.length, total: race.total }) }}
          </li>
          <li v-if="hours.length === 1">
            {{ $t('show.from_hour', { date: hours }) }}
          </li>
          <li v-if="hours.length === 2">
            {{ $t('show.from_to_hour', { from: hours[0], to: hours[1] }) }}
          </li>
        </ul>
      </div>
      <div class="totals">{{ $t('show.total_results', { nb: getAllResults.length }) }}
        / {{ $t('show.nb_dogs', { nb: getAllDogs.length }) }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import dogMixin from '../common/dog.mixin';

import { FETCH_EXPO } from '../store/const/actions.type';

export default {
  mixins: [dogMixin],
  name: 'expos',
  computed: {
    ...mapGetters([
      'getAllDogs',
      'getAllResults',
      'getDogsStatusByRace',
      'getRaceById',
      'isLoading',
      'currentUser',
      'currentExpo',
      'currentLang',
    ]),
  },
  mounted() {
    // this.fetchEvents();
  },
  methods: {
    resync() {
      if (window.confirm(this.$t('global.confirm_resync'))) {
        this.$store.dispatch(FETCH_EXPO);
      }
    },
    // },
    getBoundaries(dogs) {
      if (!dogs || dogs.length === 0) {
        return [];
      }
      let minDate = this.getHoursToNumber(dogs[0].heure_passage_classe);
      let maxDate = minDate;
      // console.log(dogs[0].heure_passage_classe, minDate);
      for (let i = 0; i < dogs.length; i += 1) {
        const currentDate = this.getHoursToNumber(dogs[i].heure_passage_classe);
        if (currentDate !== 0) {
          minDate = Math.min(currentDate, minDate);
          maxDate = Math.max(currentDate, maxDate);
        }
      }
      if (minDate === maxDate) {
        if (minDate === 0) {
          return [];
        }
        return [this.getNumberToHours(minDate)];
      }

      return [this.getNumberToHours(minDate), this.getNumberToHours(maxDate)];
    },
  },
};
</script>

<style lang="scss">
  .page-expos {
    .loading { color: blue }
    .reload {
      float: right;
      svg {
        font-size: 1.5rem;
        &:hover {color: #1193A8; cursor: pointer}
      }
    }
    .race { margin-bottom: 1rem; }
    .completed button {
      background: url('~@/assets/check.png') no-repeat right #B8D7AD; color: #673445;
      background-size: contain;
      &:hover{cursor: pointer; background-color: #C8E7BD}
    }
    h1 { border:1px gray solid; padding: .2rem 2rem; width:min(400px, 60vw); margin: auto;
      background-color: #F5F5FF; box-sizing: border-box; }
    h2, h4 { text-align: center}
    button{
      width: 100%; background-color: #c8c9cb; font-weight: 500; padding:1rem 0; color: black;
      text-transform: uppercase; line-height: .8rem; font-size: 1rem; border:1px #999 solid;
      &:hover{cursor: pointer; background-color: #B8B9BB}
    }
    ul {margin: .5rem 0 1rem 1rem; > * {font-weight: 500; font-size: 1rem} }

    .totals {border: 1px #CCC solid; text-align: right; padding: 1rem; font-weight: bold}
  }
</style>
